/* ADD GLOBAL STYLES HERE - FONTS, HEIGHTS, OVERRIDES, ETC */
#root {
  height: 100vh;
  width: 100vw;
}

body {
  margin: 0;
  font-family: "Orbitron", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
}
